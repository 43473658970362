<div class="container">
  <h1>SIGNE-COVID</h1>
  <h3 (click)="openProtectDialog()">l'application qui vous <span class="link">protège</span></h3>
  <h3 routerLink="/tutorial">en <span class="link">3 étapes</span></h3>

  <!-- START Button-->
  <div class="start-btn-container" routerLink="/qrReader">
    <img src="assets/images/start-button.png" class="start-img">
    <div class="start-label"> Démarrer</div>
  </div>

  <!-- HISTORY -->
  <div class="bottom-link">
    <h3 class="link" routerLink="/historic">Mon historique</h3>
    <h3 class="link" (click)="openPrivacyDialog()">Protection des données</h3>
    <a class="link" href="mailto:webmaster@crossappstudio.com?subject=%5Bsign-covid%5D">
      <h3>contact</h3>
    </a>

  </div>

  <!-- <div class="contact-container">
    contact
  </div> -->

</div>
