export interface Shop {
  shopId: string;
  shopName: string;
  street: string;
  zipCode: string;
  city: string;
  phoneNumber: string;
  email: string;
  website?: string;
  qrCode: string;
  creationDate: string;
}

export const mokedShop = {
  shopId: '1',
  shopName: 'HOOPER',
  street: '90 cours Gambetta',
  zipCode: '69007',
  city: 'LYON',
} as Shop
