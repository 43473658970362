<div class="header">HYGIENE</div>
<div class="text">Vous ne touchez plus aux stylos déjà manipulés</div>

<div class="header">CONFIDENTIALITE</div>
<div class="text">Vos informations ne circulent plus sur une feuille</div>

<div class="header">PREVENTION</div>
<div class="text">Vous êtes appelé en cas de cas contact</div>

<div mat-dialog-actions class="button-row">
  <button mat-button class="cv-btn-x1" [mat-dialog-close] cdkFocusInitial="false">Merci</button>
</div>
