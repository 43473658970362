<!-- card wrapper-->
<div class="register-card">

  <!-- position icon -->
  <div class="register-col1">
    <img src="assets/images/calendar-green.png" class="register-img">
  </div>

  <!-- date -->
  <div class="register-col2">
    <div class="rowBig"> {{date | date :'EEEE d MMMM'}}</div>
    <div class="rowSmall">{{date | date :'H:mm'}} </div>
  </div>
</div>
