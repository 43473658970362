import { animate, animateChild, group, query, sequence, stagger, style, transition } from '@angular/animations';

const enum anim_duration {
  "card" = 1000,
  "arrow" = 500,
};

export const animSteps = [
  transition(':enter',
    // start both cleaning and sequence animation
    group([
      // hide all fadeIn elements
      query('.fadeInElt', [
        style({ opacity: 0 })
      ]),
      // start sequence animation (slideUp and fadeIn)
      sequence([
        // slide up sequencially all slideUp elements
        query('.stepBlock', [
          stagger(1700,  // animate sequentially each block
            animateChild()
          )
        ], { optional: true }),
        // fade in all fadeIn elements
        query('.fadeInElt', [
          animate(anim_duration.card + anim_duration.arrow + 'ms 200ms', style({ opacity: '*' }))
        ], { optional: true })
      ])
    ])
  )
]

export const animStep = [
  transition(':enter', [
    sequence([
      query('.stepCard', [
        style({ opacity: 0 }),
        animate(anim_duration.card + 'ms ease-in', style({ opacity: 1 }))
      ], { optional: true }),
      query('.stepArrow', [
        sequence([
          style({ transform: 'translateY(-100px)', opacity: 0 }),
          animate(anim_duration.arrow + 'ms ease-in', style({ transform: 'translateY(0)', opacity: 1 }))
        ])
      ], { optional: true }),
    ])
  ])
]

export const openQRreader = [
  transition(':enter, :increment, :decrement', [
    style({ opacity: 0 }),
    animate('500ms 2000ms ease-in', style({ opacity: 1 }))
  ])
]

export const flashCode = [
  transition(':increment', [
    style({ opacity: 0 }),
    animate('500ms ease-in', style({ opacity: 1 }))
  ])
]
