import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from "@angular/common/locales/fr";
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HistoricComponent } from './pages/historic/historic.component';
import { HomeComponent } from './pages/home/home.component';
import { IdentityComponent } from './pages/identity/identity.component';
import { QrReaderComponent } from './pages/qr-reader/qr-reader.component';
import { RegisterComponent } from './pages/register/register.component';
import { TutorialComponent } from './pages/tutorial/tutorial.component';
import { ApiService } from './services/api.service';
import { ConfService } from './services/conf.service';
import { DateCardComponent } from './shared/date-card/date-card.component';
import { HistoCardComponent } from './shared/histo-card/histo-card.component';
import { InfoDialogComponent } from './shared/info-dialog/info-dialog.component';
import { PositionCardComponent } from './shared/position-card/position-card.component';
import { PrivacyDialogComponent } from './shared/privacy-dialog/privacy-dialog.component';
import { ProtectDialogComponent } from './shared/protect-dialog/protect-dialog.component';
import { StepHeaderCardComponent } from "./shared/step-header-card/step-header-card.component";
import { UserCardComponent } from './shared/user-card/user-card.component';


@NgModule({
  declarations: [
    AppComponent,
    QrReaderComponent,
    StepHeaderCardComponent,
    TutorialComponent,
    InfoDialogComponent,
    IdentityComponent,
    RegisterComponent,
    PositionCardComponent,
    UserCardComponent,
    DateCardComponent,
    HistoCardComponent,
    HomeComponent,
    ProtectDialogComponent,
    HistoricComponent,
    PrivacyDialogComponent
  ],
  imports: [
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatDividerModule,
    MatMenuModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ZXingScannerModule,
    MatProgressSpinnerModule,
    HttpClientModule
  ],
  providers: [
    ApiService,
    ConfService,
    { provide: LOCALE_ID, useValue: "fr" },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

registerLocaleData(localeFr, "fr");
