import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'step-header-card',
  templateUrl: './step-header-card.component.html',
  styleUrls: ['./step-header-card.component.scss']
})
export class StepHeaderCardComponent implements OnInit {


  @Input()
  stepNumber: number = null;


  @Input()
  stepLabel: string;


  constructor() { }

  ngOnInit(): void {
  }

}
