import { Injectable } from '@angular/core';
import { User } from '../models/User';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private UUID: string;

  constructor() {
    // check if UUID do not exist in DB
    if (!localStorage.getItem('UUID')) {
      // create UUID
      const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
      // save UUID
      localStorage.setItem('UUID', uuid.toString());
      this.UUID = uuid;
    }
    else {
      // get UUI from DB
      this.UUID = localStorage.getItem('UUID');
    }

  }

  loadUser(): User {
    return JSON.parse(localStorage.getItem('user')) || {};
  };

  saveUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUUID() {
    return this.UUID;
  }
}
