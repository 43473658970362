import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Shop } from '../models/Shop';
import { Visit } from '../models/Visit';
import { UserService } from './user.service';

const baseUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private userservice: UserService) {
  }

  getShopList(): Observable<Shop[]> {
    return (this.http.get<Shop[]>(baseUrl + "shop"));
  }

  getShop(shopID: string): Observable<Shop> {
    return (this.http.get<Shop>(baseUrl + "shop/" + shopID));
  }

  addVisit(visit: Visit): Observable<Visit> {
    visit.useruuid = this.userservice.getUUID();
    return (this.http.post<Visit>(baseUrl + "visit/", visit));
  }
}
