import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-histo-card',
  templateUrl: './histo-card.component.html',
  styleUrls: ['./histo-card.component.scss']
})
export class HistoCardComponent implements OnInit {

  @Input()
  date: Date;

  @Input()
  shopName: Date;

  @Output()
  delete = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  deleteItem(): void {
    this.delete.emit("");
  }
}
