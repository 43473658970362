<!-- card wrapper-->
<div class="register-card">

  <!-- position icon -->
  <div class="register-col1">
    <img src="assets/images/user-green.png" class="register-img">
  </div>

  <!-- shop address-->
  <div class="register-col2">
    <div class="rowBig"> {{user.firstName}} {{user.lastName}}</div>
    <div class="rowSmall">{{user.phoneNumber}}</div>
    <div *ngIf="user.email" class="rowSmall">{{user.email}}</div>
  </div>
</div>
