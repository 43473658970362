import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/models/User';
import { ApiService } from 'src/app/services/api.service';
import { ConfService } from 'src/app/services/conf.service';
import { RegisterService } from 'src/app/services/register.service';
import { UserService } from 'src/app/services/user.service';
import { InfoDialogComponent } from 'src/app/shared/info-dialog/info-dialog.component';

@Component({
  selector: 'app-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.scss']
})
export class IdentityComponent implements OnInit, OnDestroy {

  public identityForm: FormGroup;
  public savedUser: User;
  public isAutoComplete: boolean;
  private unsubscribe$ = new Subject();

  constructor(
    private conf: ConfService,
    public register: RegisterService,
    private user: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    // create form
    this.identityForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {

    // set title
    this.conf.setTitle('Identité');

    //get auto-complete state
    this.isAutoComplete = this.conf.getAutoFillInfos().value;

    //get routing parameter (if any)
    const shopId = this.route.snapshot.params.shopId;

    if (shopId) {
      // enter in registering mode:
      this.register.setRegisteringON();

      // if no shop or invalid shop stored in registration service:
      if (true) {//!this.register.getShop() || this.register.getShop()?.shopId !== shopId) {
        // get shop info from database
        this.api.getShop(shopId).subscribe(
          (shop) => {
            // save shop to RegisterService
            this.register.setShop(shop);
          },
          (err) => {
            let errTitle, errMsg: string;

            // http error handling
            switch (err.status) {
              case 0:
                errTitle = 'Aucune connection réseau';
                errMsg = 'Vous devez avoir accès à internet pour utiliser ce service.' + JSON.parse(err);
                break;
              case 404:
                errTitle = 'Page Introuvable';
                errMsg = 'Aucune information ne correspond aux données envoyées. Veuillez réessayer.'
                break;
              case 500:
              case 504:
                errTitle = 'Serveur indisponible';
                errMsg = 'Le serveur ne répond pas. Veuillez contacter la hotline.'
                break;
              default:
                errTitle = 'Erreur inconnue';
                errMsg = 'Error code =' + err.status + '. Veuillez contacter la hotline.'
            }

            //display error popup
            const dialogRef = this.dialog.open(InfoDialogComponent, {
              width: '250px',
              data: { title: errTitle, msg: errMsg }
            });

            dialogRef.afterClosed().subscribe(result => {
              // redirect to QRcode reader
              this.router.navigate(['qrReader']);
            });

            return;
          }
        );
      }
    };


    // subscribe to autoFill changes
    this.conf.getAutoFillInfos()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (autoFill) => {
          // update auto-complete toggle button
          this.isAutoComplete = autoFill;

          // init user
          this._resetUserInput();
        }
      )
  }

  private _resetUserInput() {
    // get saved User
    this.savedUser = this.user.loadUser();

    if (this.isAutoComplete || !this.register.isRegistering()) {

      // fill with saved user data if any
      if (Object.keys(this.savedUser).length !== 0 || this.savedUser.constructor !== Object) {
        this.identityForm.controls['firstName'].setValue(this.savedUser.firstName);
        this.identityForm.controls['lastName'].setValue(this.savedUser.lastName);
        this.identityForm.controls['phoneNumber'].setValue(this.savedUser.phoneNumber);
      }
      else {
        // keep using current data and save them
        this.savedUser.firstName = this.identityForm.get('firstName').value;
        this.savedUser.lastName = this.identityForm.get('lastName').value;
        this.savedUser.phoneNumber = this.identityForm.get('phoneNumber').value;
        this.user.saveUser(this.savedUser);
      }
    }
    else {
      // fill with empty data
      this.identityForm.controls['firstName'].setValue('');
      this.identityForm.controls['lastName'].setValue('');
      this.identityForm.controls['phoneNumber'].setValue('');
    }

  }

  autoFillChange(event: MatSlideToggleChange): void {
    this.conf.setAutoFillInfos(event.checked);
  }

  onBack(): void {
    this.router.navigate(['qrReader']);
  }

  onNext() {
    if (this.identityForm.valid) {
      this.savedUser.firstName = this.identityForm.get('firstName').value;
      this.savedUser.lastName = this.identityForm.get('lastName').value;
      this.savedUser.phoneNumber = this.identityForm.get('phoneNumber').value;

      // save user infos (for auto fill)
      this.user.saveUser(this.savedUser);

      // save user infos into register service
      this.register.setUser(this.savedUser);

      // navigate to sign page
      this.router.navigate(['register']);
    }
  }

  onCancel() {
    this.router.navigate(['/home']);
  }

  onSubmit() {
    this.savedUser.firstName = this.identityForm.get('firstName').value;
    this.savedUser.lastName = this.identityForm.get('lastName').value;
    this.savedUser.phoneNumber = this.identityForm.get('phoneNumber').value;
    this.user.saveUser(this.savedUser);
    this.router.navigate(['/home']);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
