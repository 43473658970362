import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Visit } from 'src/app/models/Visit';
import { ConfService } from 'src/app/services/conf.service';
import { RegisterService } from 'src/app/services/register.service';

@Component({
  selector: 'app-historic',
  templateUrl: './historic.component.html',
  styleUrls: ['./historic.component.scss']
})
export class HistoricComponent implements OnInit {

  public date: Date[];
  public history: [];

  constructor(private conf: ConfService, private register: RegisterService, private router: Router) { }

  ngOnInit(): void {
    this.conf.setTitle('Historique');

    // set registering mode OFF
    this.register.setRegisteringOFF();

    // get previous historical records from DB
    this.history = JSON.parse(localStorage.getItem('history')) || [];

    // delete old record
    const _14daysBefore = new Date();
    _14daysBefore.setDate(_14daysBefore.getDate() - 14);
    this.history.forEach((visit: Visit, index: number, object) => {
      if (new Date(visit.date) < _14daysBefore) object.splice(index, 1);
    }
    );
    // save update to DB
    localStorage.setItem('history', JSON.stringify(this.history));

    /* get date list
    const dates = this.history.map((record) => new Date(record.date));
    this.date = [...new Set(dates)];*/
  }

  delete(index: number) {
    this.history.splice(index, 1);

    // save update to DB
    localStorage.setItem('history', JSON.stringify(this.history));
  }

}
