<!-- card wrapper-->
<div class="histo-card">

  <!-- right icon  -->
  <div class="col1">
    <img src="assets/images/calendar-green.png" class="register-img">
  </div>

  <!-- info -->
  <div class="col2">
    <div class="rowBig">
      {{date | date :'EEEE d MMMM'}}
      <div class="delete" (click)="deleteItem()">
        <span class="material-icons">delete_forever</span>
      </div>

    </div>
    <div class="rowSmall">
      <div class="time">{{date | date :'H:mm'}}</div>
      <div class="shop">lieu : {{shopName}}</div>
    </div>
  </div>

</div>
