<!-- card wrapper-->
<div class="register-card">

  <!-- position icon -->
  <div class="register-col1">
    <img src="assets/images/position-green.png" class="register-img">
  </div>

  <!-- shop address-->
  <div class="register-col2">
    <div class="rowBig">{{shop.shopName}}</div>
    <div class="rowSmall">{{shop.street}}</div>
    <div class="rowSmall">{{shop.zipCode}} {{shop.city}}</div>
  </div>
</div>
