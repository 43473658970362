import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfService } from 'src/app/services/conf.service';
import { PrivacyDialogComponent } from 'src/app/shared/privacy-dialog/privacy-dialog.component';
import { ProtectDialogComponent } from 'src/app/shared/protect-dialog/protect-dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(public conf: ConfService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    // set title
    this.conf.setTitle('Accueil');
  }


  openProtectDialog(): void {
    const dialogRef = this.dialog.open(ProtectDialogComponent, {
      width: '250px',
    });
  }

  openPrivacyDialog(): void {
    this.dialog.open(PrivacyDialogComponent);
  }
}
