<h2 mat-dialog-title>Protection des données</h2>
<mat-dialog-content class="mat-typography">
  <p>Les informations recueillies sur ce formulaire sont enregistrées et utilisées uniquement
    par CrossAppStudio afin de lutter contre l’épidémie de COVID-19.
  </p>
  <p>Conformément aux obligations prévues dans le protocole sanitaire défini par arrêté préfectoral,
    vos données seront uniquement utilisées pour faciliter la recherche des « cas contacts » par les
    autorités sanitaires, et ne seront pas réutilisées à d’autres fins.
  </p>
  <p>En cas de contamination de l’un des clients au moment de votre présence, ces informations
    pourront être communiquées aux autorités sanitaires compétentes (agents des CPAM, de l’assurance
    maladie et/ou de l’agence régionale de santé), afin de vous contacter et de vous indiquer le
    protocole sanitaire à suivre.
  </p>
  <p>Vos données seront conservées pendant <a class="link"> 14 jours à compter de leur collecte, et seront supprimées
      à l’issue de ce délai.</a>
  </p>
  <p>Vous pouvez accéder aux données vous concernant, les rectifier ou exercer votre droit à la limitation
    du traitement de vos données.
  </p>
  <p>
    Pour exercer ces droits ou pour toute question sur le traitement de vos données, vous pouvez contacter
    <a class="link" href="mailto:webmaster@crossappstudio.com?subject=%5Bsign-covid%5D">le webmaster</a>
  </p>
  <p>
    Si vous estimez, après nous avoir contactés, que vos droits Informatique et Libertés ne sont pas respectés,
    vous pouvez adresser une réclamation à la CNIL.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
</mat-dialog-actions>
