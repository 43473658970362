<div class="app-container">
  <mat-toolbar class="toolbar">
    <button *ngIf="sideMenuStatus; else home" mat-icon-button class="" (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <ng-template #home>
      <button mat-icon-button class="" (click)="openHome()">
        <mat-icon>home</mat-icon>
      </button>
    </ng-template>
    <span> {{headertitle}}</span>

    <div>
      <button mat-icon-button [matMenuTriggerFor]="beforeMenu" *ngIf="showSettings">
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #beforeMenu="matMenu" xPosition="before">
        <div class="check-box-menu">
          <mat-checkbox [checked]="sideMenuStatus" (change)="sideMenuOnOff($event)">side menu</mat-checkbox>
          <mat-checkbox [checked]="demoModeStatus" (change)="demoModeOnOff($event)">demo mode</mat-checkbox>
          <!-- <button mat-menu-item>item</button> -->
        </div>
      </mat-menu>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav mode="none" class="sidenav-menu">
      <mat-divider> </mat-divider>
      <div (click)="openHome();sidenav.toggle()">
        <h3>Accueil</h3>
      </div>
      <mat-divider> </mat-divider>
      <div (click)="openTutorial();sidenav.toggle()">
        <h3>Tutoriel</h3>
      </div>
      <mat-divider> </mat-divider>
      <div (click)="openQRreader();sidenav.toggle()">
        <h3> <span class="material-icons">
            play_arrow
          </span> Démarrer</h3>
      </div>
      <mat-divider></mat-divider>
      <div (click)="openIdentity();sidenav.toggle()">
        <h3>Saisir mes infos</h3>
      </div>
      <mat-divider></mat-divider>
      <div (click)="openHistoric();sidenav.toggle()">
        <h3>Historique</h3>
      </div>
      <mat-divider></mat-divider>
      <div (click)="openPrivacyDialog();sidenav.toggle()">
        <h3>Protection des données</h3>
      </div>
      <mat-divider></mat-divider>
      <!-- <h3>Preferences</h3>
      <div class="sidenav-menu-item">
        <mat-slide-toggle color="primary" [checked]="autoFill" (change)="autoFillChange()"> Saisie automatique
        </mat-slide-toggle>
      </div>
      <div class="sidenav-menu-item">
        <mat-slide-toggle color="primary" [checked]="autoSend" (change)="autoSendChange()"> Envoi
          automatique
        </mat-slide-toggle>
      </div>
      <mat-divider></mat-divider>
      <h3>Configuration</h3>
      <div class="sidenav-menu-item">
        <mat-slide-toggle color="primary" [checked]="qrStatus" (change)="qrCodeChange()"> Lecteur QR Code
        </mat-slide-toggle>
      </div> -->
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content" [@routeAnimations]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
