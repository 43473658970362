import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterOutlet } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { slideInAnimation } from './animations/slide-page';
import { ConfService } from './services/conf.service';
import { RegisterService } from './services/register.service';
import { PrivacyDialogComponent } from './shared/privacy-dialog/privacy-dialog.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'sign-cv19';

  public sideMenuStatus: boolean;
  public demoModeStatus: boolean;
  public qrStatus: boolean;
  public autoFill: boolean;
  public autoSend: boolean;
  public headertitle: string;
  public showSettings: boolean;
  private unsubscribe$ = new Subject();

  constructor(
    private router: Router,
    private conf: ConfService,
    private register: RegisterService,
    private dialog: MatDialog

  ) {
    this.showSettings = !environment.production;
  }

  ngOnInit() {

    // init Side Menu status
    this.conf.getSideMenuStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => this.sideMenuStatus = value);

    // init Demo Mode status
    this.conf.getDemoModeStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => this.demoModeStatus = value);

    // init QR code Reader status
    this.conf.getQRstatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => this.qrStatus = value);

    // init Auto Fill Infos
    this.conf.getAutoFillInfos()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => this.autoFill = value);

    // init Auto Send Infos
    this.conf.getAutoSendInfos()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => this.autoSend = value);

    // subscribe to title change
    this.conf.getTitle()
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(delay(0))
      .subscribe((value) => this.headertitle = value)
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  qrCodeChange(): void {
    this.qrStatus = !this.qrStatus
    this.conf.setQRstatus(this.qrStatus);
  }

  autoFillChange(): void {
    this.autoFill = !this.autoFill
    this.conf.setAutoFillInfos(this.autoFill);
  }

  autoSendChange(): void {
    this.autoSend = !this.autoSend
    this.conf.setAutoSendInfos(this.autoSend);
  }

  openHome(): void {
    this.router.navigate(['home']);

    // set registering mode OFF by default
    this.register.setRegisteringOFF();
  }

  openTutorial(): void {
    this.router.navigate(['tutorial']);

    // set registering mode OFF by default
    this.register.setRegisteringOFF();
  }

  openQRreader(): void {
    this.router.navigate(['qrReader']);
  }

  openIdentity(): void {
    this.router.navigate(['identity']);

    // set registering mode OFF by default
    this.register.setRegisteringOFF();
  }

  openHistoric(): void {
    this.router.navigate(['historic']);
  }

  openPrivacyDialog(): void {
    this.dialog.open(PrivacyDialogComponent);
  }

  sideMenuOnOff(event: MatCheckboxChange): void {
    this.conf.setSideMenuStatus(event.checked);
  }

  demoModeOnOff(event: MatCheckboxChange): void {
    this.conf.setDemoModeStatus(event.checked);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
