import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Shop } from 'src/app/models/Shop';
import { User } from 'src/app/models/User';
import { ApiService } from 'src/app/services/api.service';
import { ConfService } from 'src/app/services/conf.service';
import { RegisterService } from 'src/app/services/register.service';
import { InfoDialogComponent } from 'src/app/shared/info-dialog/info-dialog.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public isRegistering: boolean;
  public shop: Shop;
  public user: User;
  public date: Date;

  constructor(
    private conf: ConfService,
    private _registerService: RegisterService,
    private _api: ApiService,
    private router: Router,
    private dialog: MatDialog) { }

  ngOnInit(): void {

    // go back home if not in registering process
    if (!this._registerService.isRegistering())
      this.router.navigate(['/home']);

    // set title
    this.conf.setTitle('Registre');

    // get SHOP from RegisterService
    this.shop = this._registerService.getShop();

    // get USER from RegisterService
    this.user = this._registerService.getUser();

    // get DATE
    this.date = new Date();
    this._registerService.setDate(this.date);
  }

  onBack(): void {
    this.router.navigate(['identity']);
  }

  onSubmit() {
    // get visit infos
    const visit = this._registerService.getVisit();

    // send visit infos to backend
    this._api.addVisit(visit)
      .subscribe(
        (visit) => {

          // Save new visit locally:
          // get previous historical records from DB
          let history = JSON.parse(localStorage.getItem('history')) || [];
          // add current record
          history.unshift({ 'date': this.date, 'user': this.user, 'shop': this.shop });
          // save new history in DB
          localStorage.setItem('history', JSON.stringify(history));

          //display success popup
          const dialogRef = this.dialog.open(InfoDialogComponent, {
            width: '250px',
            data: {
              title: "Bravo", msg: "Vous êtes bien enregistré(e). "
                + this.shop.shopName.toUpperCase()
                + " et l\'application SigneCovid vous souhaite une délicieuse visite."
            }
          });
          this.router.navigate(['historic']);
        },
        (err) => {
          const dialogRef = this.dialog.open(InfoDialogComponent, {
            width: '250px',
            data: { title: "Echec", msg: "Désolé mais votre saisie n'a pas pu être enregistrée. Merci de réessayer ou d'utiliser le registre papier." }
          });
        }
      );
  }
}
