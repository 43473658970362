<div class="page">

  <div class="header stepCard">
    <step-header-card stepNumber="3" stepLabel="Je signe le registre"></step-header-card>
  </div>

  <div class="content">
    <!-- Place -->
    <app-position-card [shop]="shop"></app-position-card>

    <!-- User-->
    <app-user-card [user]="user"></app-user-card>

    <!-- Date-->
    <app-date-card [date]="date"></app-date-card>

    <br>
  </div>
</div>

<div class="button-row">
  <button mat-raised-button color="primary" (click)="onBack()" class="cv-btn-x2">Retour</button>
  <button mat-raised-button color="primary" (click)="onSubmit()" class="cv-btn-x2">
    <img src="assets/icons/signPen.png" class="img-button">Signer</button>
</div>
