<div @onOpen>

  <div class="stepBlock" @onStep>
    <div class="arrow-row stepArrow" [ngStyle]="{'animation-delay' : '1s'}">
      <img src="assets/images/arrow-down-green.png" class="arrow-img">
    </div>
    <div class="card stepCard">
      <step-header-card stepNumber=" 1" stepLabel="je scan le QR code">
      </step-header-card>
    </div>

  </div>

  <div class="stepBlock" @onStep>
    <div class="arrow-row stepArrow">
      <img src="assets/images/arrow-down-green.png" class="arrow-img">
    </div>
    <div class="card stepCard">
      <step-header-card stepNumber="2" stepLabel="Je saisie mes infos"></step-header-card>
    </div>
  </div>


  <div class="stepBlock" @onStep>
    <div class="card stepCard">
      <step-header-card stepNumber="3" stepLabel="Je signe le registre"></step-header-card>
    </div>
  </div>

  <div class="button-row fadeInElt">
    <button mat-raised-button color="primary" (click)="start()" class="cv-btn-x1">OK, je démarre</button>
  </div>

</div>
