import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-protect-dialog',
  templateUrl: './protect-dialog.component.html',
  styleUrls: ['./protect-dialog.component.scss']
})
export class ProtectDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
