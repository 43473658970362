import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HistoricComponent } from './pages/historic/historic.component';
import { HomeComponent } from './pages/home/home.component';
import { IdentityComponent } from './pages/identity/identity.component';
import { QrReaderComponent } from './pages/qr-reader/qr-reader.component';
import { RegisterComponent } from './pages/register/register.component';
import { TutorialComponent } from './pages/tutorial/tutorial.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, data: { animation: 'homePage' } },
  { path: 'tutorial', component: TutorialComponent, data: { animation: 'tutorialPage' } },
  { path: 'qrReader', component: QrReaderComponent, data: { animation: 'qrReaderPage' } },
  { path: 'identity', component: IdentityComponent, data: { animation: 'identityPage' } },
  { path: 'identity/:shopId', component: IdentityComponent, data: { animation: 'identityPage' } },
  { path: 'register', component: RegisterComponent, data: { animation: 'registerPage' } },
  { path: 'historic', component: HistoricComponent, data: { animation: 'historicPage' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
