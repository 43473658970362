<div class="page">
  <div class="header stepCard">
    <step-header-card stepNumber="1" stepLabel="je scan le QR code"></step-header-card>
  </div>
  <div class="content">

    <mat-card>
      <div class="qr-square" [@onGetQR]="QRdetected">

        <div *ngIf="!QRenabled ; else QRreader" class="no-camera">
          <span class="material-icons"> no_photography </span>
          <div>camera désactivée</div>
        </div>
        <ng-template #QRreader>

          <!-- BACKGROUND SPINNER-->
          <div class="spinner" *ngIf="hasPermission; else noPermission">
            <mat-spinner color="accent"></mat-spinner>
          </div>

          <!-- NO PERMISSION-->
          <ng-template #noPermission class="no-camera">
            <div class="no-camera">
              <span class="material-icons"> lock </span>
              <div>accès caméra non autorisé</div>
            </div>
          </ng-template>

          <!-- ZXing QR READER-->
          <zxing-scanner [@onQRreader]="currentIndex" class="zxing-display" [device]="currentDevice"
            (scanSuccess)="onCodeResult($event)" [formats]="formatsEnabled"
            (permissionResponse)="onHasPermission($event)" (camerasFound)="onCamerasFound($event)">
          </zxing-scanner>

          <!-- X-RAY ANIMATED BAR-->
          <div class="x-ray" [@onQRreader]="currentIndex" *ngIf="currentDevice"></div>

          <!-- FLIP CAMERA ICON-->
          <div class="flip-icon" [@onQRreader]="currentIndex" *ngIf="currentDevice && deviceList?.length>1"
            (click)="switchCamera()">
            <img class="" src="assets/icons/icon-flip-camera.png" />
          </div>
        </ng-template>

      </div>
    </mat-card>
  </div>
</div>
<div class="button-row">
  <button mat-raised-button color="primary" class="cv-btn-x1" (click)="startStop()">{{startStopBtnLabel}}</button>
</div>
