import { trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { animStep, animSteps } from 'src/app/animations/animation';
import { ConfService } from 'src/app/services/conf.service';
import { RegisterService } from 'src/app/services/register.service';


@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
  animations: [
    trigger('onOpen', animSteps),
    trigger('onStep', animStep)
  ]
})
export class TutorialComponent implements OnInit {

  constructor(private conf: ConfService, private register: RegisterService, private router: Router) { }


  ngOnInit(): void {
    this.conf.setTitle('Tutoriel');

    // set registering mode OFF
    this.register.setRegisteringOFF();
  }

  start(): void {
    this.router.navigate(['qrReader']);
  }

}
