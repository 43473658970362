import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfService {

  private sideMenuStatus = new BehaviorSubject<boolean>(false);
  private demoModeStatus = new BehaviorSubject<boolean>(false);
  private qrReaderStatus = new BehaviorSubject<boolean>(true);
  private autoFillInfos = new BehaviorSubject<boolean>(true);
  private autoSendInfos = new BehaviorSubject<boolean>(true);
  private title = new BehaviorSubject<string>("Bienvenue");


  constructor() {
    let value: string;

    // init Side Menu Status
    value = localStorage.getItem('sideMenuStatus');
    if (value !== undefined)
      this.sideMenuStatus.next(value == 'true');
    else
      this.sideMenuStatus.next(false);

    // init Demo Mode Status
    value = localStorage.getItem('demoModeStatus');
    if (value !== undefined)
      this.demoModeStatus.next(value == 'true');
    else
      this.demoModeStatus.next(false);

    // init QR code Reader Status
    value = localStorage.getItem('qrReaderStatus');
    if (value !== undefined)
      this.qrReaderStatus.next(value == 'true');
    else
      this.qrReaderStatus.next(true);

    // init auto fill infos
    value = localStorage.getItem('autoFillInfos');
    if (value != undefined)
      this.autoFillInfos.next(value == 'true');
    else
      this.autoFillInfos.next(true);

    // init auto fill infos
    value = localStorage.getItem('autoSendInfos');
    if (value != undefined)
      this.autoSendInfos.next(value == 'true');
    else
      this.autoSendInfos.next(true);

  }

  // set Side Menu On/Off
  setSideMenuStatus(status: boolean) {
    this.sideMenuStatus.next(status);
    localStorage.setItem('sideMenuStatus', JSON.stringify(status));
  }

  getSideMenuStatus(): BehaviorSubject<boolean> {
    return this.sideMenuStatus;
  }

  // set Side Menu On/Off
  setDemoModeStatus(status: boolean) {
    this.demoModeStatus.next(status);
    localStorage.setItem('demoModeStatus', JSON.stringify(status));
  }

  getDemoModeStatus(): BehaviorSubject<boolean> {
    return this.demoModeStatus;
  }
  // manage QR code Reader Status ( active or not)
  setQRstatus(status: boolean) {
    this.qrReaderStatus.next(status);
    localStorage.setItem('qrReaderStatus', JSON.stringify(status));
  }

  getQRstatus(): BehaviorSubject<boolean> {
    return this.qrReaderStatus;
  }

  // manage auto Fill infos
  setAutoFillInfos(status: boolean) {
    this.autoFillInfos.next(status);
    localStorage.setItem('autoFillInfos', JSON.stringify(status));
  }

  getAutoFillInfos(): BehaviorSubject<boolean> {
    return this.autoFillInfos;
  }

  // manage auto Send infos
  setAutoSendInfos(status: boolean) {
    this.autoSendInfos.next(status);
    localStorage.setItem('autoSendInfos', JSON.stringify(status));
  }

  getAutoSendInfos(): BehaviorSubject<boolean> {
    return this.autoSendInfos;
  }

  // set title
  setTitle(title: string): void {
    this.title.next(title);
  }

  // get title
  getTitle(): BehaviorSubject<string> {
    return this.title;
  }
}
