<mat-card class="step-header-container">

  <!-- Step NUMBER in circle (if any)-->
  <div *ngIf="stepNumber !== null" class="step-header-number">
    {{stepNumber}}
  </div>

  <!-- Step LABEL-->
  <div class="step-header-label">
    {{stepLabel}}
  </div>

  <!-- Step Right Side-->
  <div class="step-header-right"></div>

</mat-card>
