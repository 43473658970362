import { Injectable } from '@angular/core';
import { Shop } from '../models/Shop';
import { User } from '../models/User';
import { Visit } from '../models/Visit';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  private _isRegistering = false;
  private _user: User;
  private _shop: Shop;
  private _date: Date;

  constructor() { }

  setRegisteringON() {
    this._isRegistering = true;
  }

  setRegisteringOFF() {
    this._isRegistering = false;
  }

  isRegistering(): boolean {
    return this._isRegistering;
  }

  getShop(): Shop {
    return (this._shop);
  }

  setShop(shop: Shop) {
    this._shop = shop;
  }

  getUser(): User {
    return (this._user);
  }

  setUser(user: User) {
    this._user = user;
  }

  getDate(): Date {
    return (this._date);
  }

  setDate(date: Date) {
    this._date = date;
  }

  getVisit(): Visit {
    let visit = {} as Visit;

    let today = new Date(this._date);
    // format current date to SQL format
    let date = today.toLocaleDateString().split('/'); // = "21/05/2021"
    visit.date = date[2] + '-' + date[1] + '-' + date[0] + ' ' + today.toLocaleTimeString();
    visit.firstName = this._user.firstName;
    visit.lastName = this._user.lastName;
    visit.phoneNumber = this._user.phoneNumber;
    visit.shopId = this._shop.shopId;
    return visit;
  }

}
