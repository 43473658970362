import { trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BarcodeFormat } from '@zxing/library';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { animStep, flashCode, openQRreader } from 'src/app/animations/animation';
import { mokedShop } from 'src/app/models/Shop';
import { ApiService } from 'src/app/services/api.service';
import { ConfService } from 'src/app/services/conf.service';
import { RegisterService } from 'src/app/services/register.service';
import { InfoDialogComponent } from 'src/app/shared/info-dialog/info-dialog.component';

@Component({
  selector: 'app-qr-reader',
  templateUrl: './qr-reader.component.html',
  styleUrls: ['./qr-reader.component.scss'],
  animations: [
    trigger('onStep', animStep),
    trigger('onQRreader', openQRreader),
    trigger('onGetQR', flashCode)
  ]
})
export class QrReaderComponent implements OnInit, OnDestroy {

  deviceList: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  currentIndex: number = null;
  formatsEnabled: BarcodeFormat[] = [
    // BarcodeFormat.CODE_128,
    // BarcodeFormat.DATA_MATRIX,
    // BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  QRdetected = 0;
  QRenabled: boolean;
  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  errlog = null;

  private demoTimer: any;
  public startStopBtnLabel = 'Start';
  private unsubscribe$ = new Subject();


  constructor(
    private router: Router,
    private conf: ConfService,
    private api: ApiService,
    public register: RegisterService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    // set title
    this.conf.setTitle('Lecteur QR code');

    // set default permission to true (waiting for zxing result)
    this.hasPermission = true;

    // subcribe to QRstatus On/Off changes
    this.conf.getQRstatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.QRenabled = value;
        if (this.QRenabled)
          this.startStopBtnLabel = 'Pause';
        else
          this.startStopBtnLabel = 'Activer caméra';
      })

    // subcribe to deMoMode On/Off changes
    this.conf.getDemoModeStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value)
          this.startDemoTimer();
        else
          this.stopDemoTimer();
      })
    //

    // start timer if demo mode already set:
    if (this.conf.getDemoModeStatus().value)
      this.startDemoTimer();

    // set registering mode ON
    this.register.setRegisteringON();
  }

  startDemoTimer(): void {
    if (!this.demoTimer)
      this.demoTimer = setTimeout(() => {
        // save moked shop to RegisterService
        this.register.setShop(mokedShop);

        // navigate to identity page
        this.router.navigate(['identity']);

      }, 4000);
  }

  stopDemoTimer() {
    clearTimeout(this.demoTimer);
  }

  startStop() {
    this.conf.setQRstatus(!this.QRenabled);
  }

  switchCamera(): void {
    if (this.deviceList && this.deviceList.length) {
      this.currentIndex = ++this.currentIndex % (this.deviceList.length);
      this.currentDevice = this.deviceList[this.currentIndex];
    }
  }


  extractShopID(url: string): string {
    //id = +url.split('/').pop();
    return url.split('/').pop() || null;
  }

  /**************************************************************************
   *                        ZXing QRcode READER methods
   **************************************************************************/

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onCamerasFound(list: MediaDeviceInfo[]): void {
    var index;
    this.deviceList = list;

    if (this.deviceList && this.deviceList.length) {
      this.currentIndex = this.deviceList.findIndex(x => x.label.toUpperCase().includes("BACK"));
      if (this.currentIndex < 0) this.currentIndex = 0
      this.currentDevice = this.deviceList[this.currentIndex];

    }
    else {
      this.currentIndex = null;
      this.currentDevice = null;
    }
  }

  onCodeResult(resultString: string) {
    // increment QRdetected to trigger animation
    if (this.QRdetected <= 0)
      this.QRdetected++;
    else
      return;
    console.log("qr detected");

    // extract ID from resultString
    let shopID = this.extractShopID(resultString);

    // return if not a valid number
    if (shopID === null) {
      //display error popup
      const dialogRef = this.dialog.open(InfoDialogComponent, {
        width: '250px',
        data: { title: "QR code invalide", msg: "le QR code n'est pas reconnu par l'application" }
      });

      dialogRef.afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(result => {
          // restart QRreader
          this.QRdetected = 0;
        });

      return;
    }

    // get shop info from database
    this.api.getShop(shopID).subscribe(
      (shop) => {
        // save shop to RegisterService
        this.register.setShop(shop);

        // navigate to identity page
        this.router.navigate(['identity', shopID]);
      },
      (err) => {
        let errTitle, errMsg: string;

        // http error handling
        switch (err.status) {
          case 0:
            errTitle = 'Aucune connection réseau ou CORS';
            errMsg = 'Vous devez avoir accès à internet pour utiliser ce service.';
            this.errlog = err.message;
            break;
          case 404:
            errTitle = 'Page Introuvable';
            errMsg = 'Aucune information ne correspond aux données envoyées. Veuillez réessayer.'
            break;
          case 500:
          case 504:
            errTitle = 'Serveur indisponible';
            errMsg = 'Le serveur ne répond pas. Veuillez contacter la hotline.'
            break;
          default:
            errTitle = 'Erreur inconnue';
            errMsg = 'Error code =' + err.status + '. Veuillez contacter la hotline.'
        }

        //display error popup
        const dialogRef = this.dialog.open(InfoDialogComponent, {
          width: '250px',
          data: { title: errTitle, msg: errMsg }
        });

        dialogRef.afterClosed().subscribe(result => {
          // restart QRreader
          this.QRdetected = 0;
        });

        return;

      });

  }

  ngOnDestroy(): void {

    // clear current device
    this.currentDevice = null;

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
