import { Component, Input, OnInit } from '@angular/core';
import { Shop } from 'src/app/models/Shop';

@Component({
  selector: 'app-position-card',
  templateUrl: './position-card.component.html',
  styleUrls: ['./position-card.component.scss']
})
export class PositionCardComponent implements OnInit {

  @Input()
  shop: Shop

  constructor() { }

  ngOnInit(): void {
  }

}
