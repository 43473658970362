<div class="page">

  <div class="header stepCard">
    <step-header-card [stepNumber]="register.isRegistering()?'2':null" stepLabel="Je saisie mes infos">
    </step-header-card>
  </div>
  <div class="content">
    <!-- user form-->
    <form [formGroup]="identityForm">
      <mat-card class="information-card">

        <mat-card-content class="information-data">

          <mat-form-field>
            <mat-label>Prénom</mat-label>
            <input matInput type="text" formControlName="firstName">
            <button mat-button *ngIf="identityForm.get('firstName').value" matSuffix mat-icon-button aria-label="Clear"
              (click)="identityForm.get('firstName').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Nom</mat-label>
            <input matInput type="text" formControlName="lastName">
            <button mat-button *ngIf="identityForm.get('lastName').value" matSuffix mat-icon-button aria-label="Clear"
              (click)="identityForm.get('lastName').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Téléphone</mat-label>
            <input matInput type="text" formControlName="phoneNumber">
            <button mat-button *ngIf="identityForm.get('phoneNumber').value" matSuffix mat-icon-button
              aria-label="Clear" (click)="identityForm.get('phoneNumber').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <!-- autocomplete toggle button-->
          <div class="toggle-row">
            <mat-slide-toggle *ngIf="register.isRegistering()" color="primary" [checked]="isAutoComplete"
              (change)="autoFillChange($event)"> se souvenir de moi
            </mat-slide-toggle>
          </div>
        </mat-card-content>

      </mat-card>
    </form>
    <br>
  </div>



</div>
<!-- buttons row (back/next or save)-->
<div *ngIf="register.isRegistering(); else submit" class="button-row">
  <button mat-raised-button color="primary" (click)="onBack()" class="cv-btn-x2">Retour</button>
  <button mat-raised-button color="primary" (click)="onNext()" class="cv-btn-x2"
    [disabled]="!identityForm.valid">Suite</button>
</div>
<ng-template #submit>
  <div class="button-row">
    <button mat-raised-button color="primary" (click)="onCancel()" class="cv-btn-x2">Annuler</button>
    <button mat-raised-button color="primary" (click)="onSubmit()" class="cv-btn-x2">Enregistrer</button>
  </div>
</ng-template>
